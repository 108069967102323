import { useEffect, useState, useRef } from "react";
import * as jose from "jose";
import { tokenAtom } from "../../util/atoms";
import { useAtom } from "jotai";

const Header = ({
  toggleSidebar,
  environmentName,
  environmentColorClass,
}: {
  toggleSidebar: () => void;
  environmentName: string;
  environmentColorClass: string;
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const [token, setToken] = useAtom(tokenAtom);
  const [tenantName, setTenantName] = useState("");
  const [shopName, setShopName] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);
  const iconRef = useRef<HTMLDivElement>(null); // アイコンの参照を取得
  const popupRef = useRef<HTMLDivElement>(null); // ポップアップの参照を取得

  useEffect(() => {
    const data = jose.decodeJwt(token as string);
    setTenantName(data.tenantName as string);
    setShopName(data.shopName as string);
  }, []);

  // ポップアップを切り替える関数
  const togglePopup = () => {
    setIsPopupVisible((prev) => !prev);
  };

  // ログアウトモーダルを開く関数
  const openLogoutModal = () => {
    setIsLogOutModalOpen(true);
  };

  // ログアウト処理を行う関数
  const handleLogout = () => {
    setToken(null);
    setIsLogOutModalOpen(false);
  };

  // ポップアップ外をクリックしたときにポップアップを閉じる
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node;

      // クリックがアイコンやポップアップ内でない場合にのみポップアップを閉じる
      if (
        isPopupVisible &&
        !popupRef.current?.contains(target) &&
        !iconRef.current?.contains(target)
      ) {
        setIsPopupVisible(false);
      }
    };

    // クリックイベントのリスナーを追加
    document.addEventListener("click", handleClickOutside);

    // コンポーネントがアンマウントされるときにリスナーを削除
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isPopupVisible]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sharedProps: SharedProps = {
    tenantName,
    shopName,
    environmentName,
    environmentColorClass,
    isPopupVisible,
    togglePopup,
    isLogOutModalOpen,
    openLogoutModal,
    handleLogout,
    iconRef,
    popupRef,
    setIsLogOutModalOpen,
  };

  return isMobile ? (
    <MobileLoginPage {...sharedProps} toggleSidebar={toggleSidebar} />
  ) : (
    <DesktopLoginPage {...sharedProps} />
  );
};

interface SharedProps {
  tenantName?: string;
  shopName?: string;
  environmentName?: string | null;
  environmentColorClass?: string;
  isPopupVisible: boolean;
  togglePopup: () => void;
  isLogOutModalOpen: boolean;
  openLogoutModal: () => void;
  handleLogout: () => void;
  iconRef: React.RefObject<HTMLDivElement>;
  popupRef: React.RefObject<HTMLDivElement>;
  setIsLogOutModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  toggleSidebar?: () => void;
}

const MobileLoginPage: React.FC<SharedProps> = ({
  isPopupVisible,
  togglePopup,
  isLogOutModalOpen,
  openLogoutModal,
  handleLogout,
  iconRef,
  popupRef,
  setIsLogOutModalOpen,
  toggleSidebar,
}) => {
  return (
    <>
      <header className="fixed z-10 top-0 left-0 box-border bg-white border-b-[1px] border-b-lightGray border-b-solid h-[54px] px-16px py-11px w-full">
        <div className="flex justify-between m-0 p-0">
          <div>
            <span
              style={{
                WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                outline: "none", // タップ時のアウトラインを無効化
              }}
              onClick={toggleSidebar}
              className="material-symbols-rounded pt-5px text-[24px]"
            >
              menu
            </span>
          </div>
          <div className="flex gap-6.99px">
            <div className="my-0 text-[16px] font-bold font-hiragino leading-8.5">
              <img src="/mobile_header_title.svg" alt="Logo" />
            </div>
            <h1 className="my-0 text-14px font-normal leading-8 font-hiragino text-secondary">
              フードロスクーポン
            </h1>
          </div>
          <div className="relative flex justify-end items-start h-32px w-32px">
            <div className="h-[40px] relative" ref={iconRef}>
              <img
                src="/shop.svg"
                alt="Logo"
                className="cursor-pointer"
                onClick={togglePopup} // クリックイベントを設定
              />
              {/* ポップアップ */}
              {isPopupVisible && (
                <div
                  ref={popupRef} // ポップアップに参照を設定
                  style={{
                    WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                    outline: "none", // タップ時のアウトラインを無効化
                  }}
                  onClick={openLogoutModal}
                  className="absolute right--5 flex w-92px h-16px px-10px py-8px mt-[8px] bg-white rounded-4px z-20 hover:bg-gray-100 hover:cursor-pointer"
                >
                  <span className="material-symbols-rounded text-[20px] rounded-999 ">
                    logout
                  </span>
                  <div className="text-14px text-[#111827] font-hiragino leading-4.7">
                    ログアウト
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isLogOutModalOpen && (
          <div
            id="modal-overlay"
            tabIndex={-1}
            onClick={() => setIsLogOutModalOpen(false)}
            aria-hidden="true"
            className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="relative w-full px-16px"
            >
              <div className="relative flex flex-col gap-[20px] box-border w-full p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow">
                <div className="flex flex-col gap-[20px]">
                  <div className="text-18px text-#111827 font-semibold text-center font-hiragino">
                    ログアウトしますか？
                  </div>
                  <div className="flex flex-col justify-center gap-12px">
                    <button
                      type="button"
                      style={{
                        WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                        outline: "none", // タップ時のアウトラインを無効化
                      }}
                      className="block h-[48px] py-[12px] px-[24px] text-[16px] font-semibold font-hiragino border-none rounded-[12px] bg-[#001EFB] text-white hover:bg-#334BFC  cursor-pointer"
                      onClick={handleLogout}
                    >
                      はい
                    </button>
                    <button
                      type="button"
                      style={{
                        WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                        outline: "none", // タップ時のアウトラインを無効化
                      }}
                      className="block h-[48px] py-[12px] px-[24px] text-[16px] text-#111827 bg-#FFF rounded-[12px] font-semibold font-hiragino border-none focus:outline-none hover:bg-gray-100  cursor-pointer focus:z-10"
                      onClick={() => {
                        setIsLogOutModalOpen(false);
                      }}
                    >
                      キャンセル
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

const DesktopLoginPage: React.FC<SharedProps> = ({
  tenantName,
  shopName,
  environmentName,
  environmentColorClass,
  isPopupVisible,
  togglePopup,
  isLogOutModalOpen,
  openLogoutModal,
  handleLogout,
  iconRef,
  popupRef,
  setIsLogOutModalOpen,
}) => {
  return (
    <>
      <header className="fixed z-10 top-0 left-0 bg-white border-b-[1px] border-b-lightGray border-b-solid h-[52px] w-full">
        <div className="flex justify-between m-0 p-0">
          <div className="flex min-w-3/5 px-[20px] py-[12px] gap-[16px]">
            <div className="flex gap-[8px]">
              <h1 className="my-0 text-[16px] font-bold font-hiragino leading-8.5">
                <img src="/header_title.svg" alt="Logo" />
              </h1>
              <h1 className="my-0 text-[16px] font-normal leading-7 font-hiragino text-secondary">
                フードロスクーポン
              </h1>
            </div>
            <div className="w-[1px] h-[28px] bg-lightGray"></div>
            <p className="my-0 text-[14px] text-secondary font-semibold font-hiragino leading-7">
              管理コンソール
            </p>
            <div className="w-[1px] h-[28px] bg-lightGray"></div>
            <p className="my-0 text-[14px] text-secondary font-semibold font-hiragino leading-7">
              ショップ用
            </p>
            <div className="w-[1px] h-[28px] bg-lightGray"></div>
            <p className="my-0 py-[4px] px-[10px] h-[20px] rounded-[5px] bg-lightGray2 text-[14px] text-[#111827] font-hiragino leading-5">
              {tenantName}
            </p>
            {environmentName && (
              <>
                <div className="w-[1px] h-[28px] bg-lightGray"></div>
                <p className="flex gap-4px my-0 py-[4px] px-[10px] h-[20px] rounded-[5px] bg-lightGray2 text-[14px] text-[#111827] font-hiragino leading-5">
                  <span
                    className={`w-8px h-8px mt-6px rounded-9999px ${environmentColorClass}`}
                  ></span>
                  {environmentName}
                </p>
              </>
            )}
          </div>
          <div className="relative flex justify-end items-start h-[52px] max-w-2/6 pr-[24px] gap-[12px]">
            <p className="m-0 text-[14px] text-[#111827] font-hiragino leading-13 truncate">
              {shopName}
            </p>
            <div className="pt-[10px] w-[40px] h-[40px] relative" ref={iconRef}>
              <img
                src="/shop.svg"
                alt="Logo"
                className="cursor-pointer"
                onClick={togglePopup} // クリックイベントを設定
              />
              {/* ポップアップ */}
              {isPopupVisible && (
                <div
                  ref={popupRef} // ポップアップに参照を設定
                  onClick={openLogoutModal}
                  className="absolute right--5 flex w-92px h-16px px-10px py-8px mt-[8px] bg-white rounded-4px z-20 hover:bg-gray-100 hover:cursor-pointer"
                >
                  <span className="material-symbols-rounded text-[20px] rounded-999 ">
                    logout
                  </span>
                  <div className="text-14px text-[#111827] font-hiragino leading-4.7">
                    ログアウト
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {isLogOutModalOpen && (
          <div
            id="modal-overlay"
            tabIndex={-1}
            onClick={() => setIsLogOutModalOpen(false)}
            aria-hidden="true"
            className="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm bg-surfaceOverlay"
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="relative w-[432px] h-[92px]"
            >
              <div className="relative flex flex-col items-start gap-[20px] w-[432px] h-[92px] p-[24px] bg-white border-[1px] border-lightGray border-solid rounded-[16px] shadow-DropShadowLow">
                <div className="flex flex-col gap-[20px]">
                  <div className="w-432px text-18px text-#111827 font-medium font-hiragino">
                    ログアウトしますか？
                  </div>
                  <div className="flex justify-end gap-12px">
                    <button
                      type="button"
                      className="block w-[136px] h-[48px] py-[12px] px-[24px] text-[16px] text-#111827 bg-#FFF rounded-[12px] font-semibold font-hiragino border-none focus:outline-none hover:bg-gray-100  cursor-pointer focus:z-10"
                      onClick={() => {
                        setIsLogOutModalOpen(false);
                      }}
                    >
                      キャンセル
                    </button>
                    <button
                      type="button"
                      className="block w-[88px] h-[48px] py-[12px] px-[24px] text-[16px] font-semibold font-hiragino border-none rounded-[12px] bg-[#001EFB] text-white hover:bg-#334BFC  cursor-pointer focus:ring-blue-300" // 通常時のスタイル
                      onClick={handleLogout}
                    >
                      はい
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </header>
    </>
  );
};

export default Header;
