import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  CreateCouponTemplateRequest,
  DeleteTemplateCouponRequest,
  DistributeCouponRequest,
  EditShopRequest,
  LoginRequest,
  StopDistributedCouponRequest,
  UpdateDistributedCouponRequest,
  UpdateTemplateCouponRequest,
} from "schema/gen/es/foodlosscoupon/shopconsole/v1/shopconsole_pb";
import { PartialMessage } from "@bufbuild/protobuf";

import { api } from "../lib/api";

export function useQueryGetTemplateCouponsByShopID(
  pageNum: number,
  limit: number
) {
  return useQuery({
    queryKey: ["getTemplateCouponsByShopID"],
    queryFn: () => api.getTemplateCouponsByShopID({ pageNum, limit }),
  });
}

export function useQueryGetTemplateCouponByID(id: string) {
  return useQuery({
    queryKey: ["getTemplateCouponByID", id],
    queryFn: () => api.getTemplateCouponByID({ id }),
  });
}

export function useQueryGetDistributedCouponsByShopID(
  pageNum: number,
  limit: number,
  isShowFinishedCoupons: boolean
) {
  return useQuery({
    queryKey: ["getDistributedCouponsByShopID"],
    queryFn: () =>
      api.getDistributedCouponsByShopID({
        pageNum,
        limit,
        isShowFinishedCoupons,
      }),
  });
}

export function useQueryGetShopByID() {
  return useQuery({
    queryKey: ["getShopByID"],
    queryFn: () => api.getShopByID({}),
  });
}

export function useQueryGetTenant() {
  return useQuery({
    queryKey: ["getTenant"],
    queryFn: () => api.getTenant({}),
    retry: 0,
  });
}

export function useMutationLogin() {
  return useMutation({
    mutationKey: ["login"],
    mutationFn: (args: PartialMessage<LoginRequest>) => api.login(args),
  });
}

export function useMutationCreateCouponTemplate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["createCouponTemplate"],
    mutationFn: (args: PartialMessage<CreateCouponTemplateRequest>) =>
      api.createCouponTemplate(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getTemplateCouponsByShopID"],
      });
    },
  });
}

export function useMutationUpdateTemplateCoupon(id: string) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["updateTemplateCoupon"],
    mutationFn: (args: PartialMessage<UpdateTemplateCouponRequest>) =>
      api.updateTemplateCoupon(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getTemplateCouponByID", id],
      });
      queryClient.invalidateQueries({
        queryKey: ["getTemplateCouponsByShopID"],
      });
    },
  });
}

export function useMutationDeleteTemplateCoupon() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["deleteTemplateCoupon"],
    mutationFn: (args: PartialMessage<DeleteTemplateCouponRequest>) =>
      api.deleteTemplateCoupon(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getTemplateCouponsByShopID"],
      });
    },
  });
}

export function useMutationDistributeCoupon() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["distributeCoupon"],
    mutationFn: (args: PartialMessage<DistributeCouponRequest>) =>
      api.distributeCoupon(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getDistributedCouponsByShopID"],
      });
    },
  });
}

export function useMutationUpdateDistributedCoupon() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["updateDistributedCoupon"],
    mutationFn: (args: PartialMessage<UpdateDistributedCouponRequest>) =>
      api.updateDistributedCoupon(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getDistributedCouponsByShopID"],
      });
    },
  });
}

export function useMutationStopDistributedCoupon() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ["stopDistributedCoupon"],
    mutationFn: (args: PartialMessage<StopDistributedCouponRequest>) =>
      api.stopDistributedCoupon(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["getDistributedCouponsByShopID"],
      });
    },
  });
}

export function useMutationEditShop() {
  return useMutation({
    mutationKey: ["editShop"],
    mutationFn: (args: PartialMessage<EditShopRequest>) => api.editShop(args),
  });
}
