import {
  useOutletContext,
  useNavigate,
  NavigateFunction,
} from "react-router-dom";
import "dayjs/locale/ja";
import * as jose from "jose";
import { useQueryGetShopByID } from "../../hooks/useQuery";
import { IQRCode, useQRCode } from "../../hooks/useQrcode";
import psImg from "../../assets/ps.png";
import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";
import { getQRURL } from "../../util/qrcode";
import { PlainMessage } from "@bufbuild/protobuf";
import { GetShopByIDResponse } from "schema/gen/es/foodlosscoupon/shopconsole/v1/shopconsole_pb";
import { ConsoleLayoutOutletContext } from "../templates/consoleLayout";

const ShopSettingPage = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);
  const navigate = useNavigate();
  const { token } = useOutletContext<ConsoleLayoutOutletContext>();
  const data = jose.decodeJwt(token as string);
  const { data: getShopByID } = useQueryGetShopByID();
  const { Canvas } = useQRCode();
  const canvasRef = useRef<HTMLDivElement>(null);
  const [isDownloadMode, setIsDownloadMode] = useState(false);

  const handleDownloadPNG = async () => {
    setIsDownloadMode(true);
    await new Promise((resolve) => setTimeout(resolve, 100));

    if (canvasRef.current) {
      const canvasImage = await html2canvas(canvasRef.current, {
        useCORS: true,
        scale: 2, // 高解像度
        logging: true,
      });

      const imgData = canvasImage.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = `${getShopByID?.shop?.name || "QR_Code"}_code.png`;
      link.click();
    }

    setIsDownloadMode(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0); // スクロール位置を一番上にリセット
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sharedProps = {
    getShopByID,
    data,
    navigate,
    handleDownloadPNG,
    Canvas,
    getQRURL,
    psImg,
    canvasRef,
    isDownloadMode,
  };

  return isMobile ? (
    <MobileLoginPage {...sharedProps} />
  ) : (
    <DesktopLoginPage {...sharedProps} />
  );
};

interface SharedProps {
  getShopByID?: PlainMessage<GetShopByIDResponse>;
  data: Record<string, unknown>;
  navigate: NavigateFunction;
  handleDownloadPNG: () => Promise<void>;
  Canvas: ({ text, options, logo }: IQRCode) => JSX.Element;
  getQRURL: (serviceId: string, slug: string) => string;
  psImg: string;
  canvasRef: React.RefObject<HTMLDivElement>;
  isDownloadMode: boolean;
}

const MobileLoginPage: React.FC<SharedProps> = ({
  getShopByID,
  data,
  navigate,
  handleDownloadPNG,
  // eslint-disable-next-line
  Canvas,
  getQRURL,
  psImg,
  canvasRef,
  isDownloadMode,
}) => {
  return (
    <>
      <div className="p-[16px]">
        <div className="flex justify-between">
          <h2 className="m-0 text-[20px] font-semibold leading-10 font-hiragino">
            ショップ設定
          </h2>
        </div>
        <div className="mt-[16px] bg-white rounded-[16px]">
          <div className="flex justify-between px-[24px] border-b-[1px] border-b-lightGray border-b-solid">
            <div className="text-18px leading-17 font-semibold font-hiragino">
              ショップ情報
            </div>
            <button
              onClick={() => navigate("/shop_edit")}
              style={{
                WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                outline: "none", // タップ時のアウトラインを無効化
              }}
              type="button"
              className="block flex gap-[2px] mt-14px w-[80px] h-[36px] py-[8px] px-[12px] rounded-[8px] bg-#F8FAFC font-hiragino border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:outline-none"
            >
              <div>
                <span className="material-symbols-rounded text-[20px] text-#111827">
                  edit
                </span>
              </div>
              <div className="text-14px font-semibold text-#111827 font-hiragino leading-4.5">
                編集
              </div>
            </button>
          </div>
          <div className="flex flex-col gap-12px p-[24px]">
            <div className="flex flex-col gap-8px">
              <div className="h-20px ext-14px font-semibold text-#111827 font-hiragino">
                ショップ名
              </div>
              <div className="text-14px text-secondary font-hiragino leading-6 break-all">
                {getShopByID?.shop?.name}
              </div>
            </div>
            <div className="flex flex-col gap-8px">
              <div className="h-20px text-14px font-semibold text-#111827 font-hiragino">
                ショップ説明
              </div>
              <div className="text-14px text-secondary font-hiragino leading-5">
                {getShopByID?.shop?.description ? (
                  <div
                    className="break-all"
                    dangerouslySetInnerHTML={{
                      __html: getShopByID?.shop?.description.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className="flex flex-col gap-8px">
              <div className="h-20px text-14px font-semibold text-#111827 font-hiragino">
                ショップ画像
              </div>
              {getShopByID?.shop?.imageBase64 ? (
                <div className="text-14px text-secondary font-hiragino ">
                  <img
                    src={getShopByID?.shop?.imageBase64}
                    alt={getShopByID?.shop?.name}
                    className="h-120px mt-10px rounded-8px border-solid border-1px border-lightGray"
                  />
                </div>
              ) : (
                <div className="h-20p text-14px text-secondary font-hiragino ">
                  -
                </div>
              )}
            </div>
            <div className="flex flex-col gap-8px">
              <div className="h-20px text-14px font-semibold text-#111827 font-hiragino">
                営業時間
              </div>
              <div className="text-14px text-secondary font-hiragino leading-5">
                {getShopByID?.shop?.openingHours ? (
                  <div
                    className="break-all"
                    dangerouslySetInnerHTML={{
                      __html: getShopByID?.shop?.openingHours.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className="flex flex-col gap-8px">
              <div className="text-14px font-semibold text-#111827 font-hiragino">
                住所
              </div>
              <div className="text-14px text-secondary font-hiragino leading-5 break-all">
                {getShopByID?.shop?.address || "-"}
              </div>
            </div>
            <div className="flex flex-col gap-8px">
              <div className="h-20px text-14px font-semibold text-#111827 font-hiragino">
                お問い合わせ（電話番号など）
              </div>
              <div className="text-14px text-secondary font-hiragino leading-5">
                {getShopByID?.shop?.phoneNumber || "-"}
              </div>
            </div>
            <div className="flex flex-col gap-8px">
              <div className="h-20px text-14px font-semibold text-#111827 font-hiragino break-all">
                ホームページURL
              </div>
              <div className="text-14px text-secondary font-hiragino leading-5">
                {getShopByID?.shop?.homepageUrl || "-"}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[24px] bg-white rounded-[16px]">
          <div className="flex justify-between h-[64px] px-[24px] border-b-[1px] border-b-lightGray border-b-solid text-18px leading-17 font-semibold font-hiragino">
            ショップ用QRコード
          </div>
          <div className="flex flex-col gap-12px  p-[24px]">
            <div className="text-12px text-secondary font-hiragino">
              こちらのQRコードを印刷してレジ前などにおいてください
            </div>
            <div className="flex flex-col gap-20px">
              <div className="w-150px h-150px">
                <Canvas
                  text={getQRURL(
                    (data?.serviceId as string) || "",
                    getShopByID?.shop?.slug || ""
                  )}
                  logo={{ src: psImg, options: { width: 30 } }}
                  options={{
                    errorCorrectionLevel: "H",
                    margin: 0,
                    scale: 4,
                    width: 150,
                  }}
                />
              </div>

              <button
                type="button"
                style={{
                  WebkitTapHighlightColor: "transparent", // iOSでのタップハイライトを無効化
                  outline: "none", // タップ時のアウトラインを無効化
                }}
                onClick={handleDownloadPNG}
                className="block w-[152px] h-[36px] py-[8px] px-[12px] rounded-[8px] bg-#F8FAFC font-hiragino border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:outline-none"
              >
                <div className="text-14px font-semibold text-#111827 font-hiragino leading-4.5">
                  ダウンロード
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={canvasRef}
        className="w-260px p-20px fixed top-[-1000px] left-[-1000px]"
        style={{ display: isDownloadMode ? "block" : "none" }}
      >
        <div className="w-260px h-260px">
          <Canvas
            text={getQRURL(
              (data?.serviceId as string) || "",
              getShopByID?.shop?.slug || ""
            )}
            logo={{
              src: psImg,
              options: { width: 53 },
            }}
            options={{
              errorCorrectionLevel: "H",
              margin: 0,
              scale: 4,
              width: 260,
            }}
          />
        </div>
        <div className="h-12px"></div>
        <div className="text-20px text-#000 text-center">
          {getShopByID?.shop?.name || ""}
        </div>
      </div>
    </>
  );
};

const DesktopLoginPage: React.FC<SharedProps> = ({
  getShopByID,
  data,
  navigate,
  handleDownloadPNG,
  // eslint-disable-next-line
  Canvas,
  getQRURL,
  psImg,
  canvasRef,
  isDownloadMode,
}) => {
  return (
    <>
      <div className="p-[24px]">
        <div className="flex justify-between">
          <h2 className="m-0 text-[20px] font-semibold leading-10 font-hiragino">
            ショップ設定
          </h2>
        </div>
        <div className="mt-[24px] bg-white rounded-[16px]">
          <div className="flex justify-between px-[24px] border-b-[1px] border-b-lightGray border-b-solid">
            <div className="text-18px leading-17 font-semibold font-hiragino">
              ショップ情報
            </div>
            <button
              onClick={() => navigate("/shop_edit")}
              type="button"
              className="block flex gap-[2px] mt-14px w-[80px] h-[36px] py-[8px] px-[12px] rounded-[8px] bg-#F8FAFC font-hiragino border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:outline-none"
            >
              <div>
                <span className="material-symbols-rounded text-[20px] text-#111827">
                  edit
                </span>
              </div>
              <div className="text-14px font-semibold text-#111827 font-hiragino leading-4.5">
                編集
              </div>
            </button>
          </div>
          <div className="flex flex-col gap-12px p-[20px]">
            <div className="flex flex-col gap-8px">
              <div className="h-20px ext-14px font-semibold text-#111827 font-hiragino">
                ショップ名
              </div>
              <div className="text-14px text-secondary font-hiragino leading-6 break-all">
                {getShopByID?.shop?.name}
              </div>
            </div>
            <div className="flex flex-col gap-8px">
              <div className="h-20px text-14px font-semibold text-#111827 font-hiragino">
                ショップ説明
              </div>
              <div className="text-14px text-secondary font-hiragino leading-5">
                {getShopByID?.shop?.description ? (
                  <div
                    className="break-all"
                    dangerouslySetInnerHTML={{
                      __html: getShopByID?.shop?.description.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className="flex flex-col gap-8px">
              <div className="h-20px text-14px font-semibold text-#111827 font-hiragino">
                ショップ画像
              </div>
              {getShopByID?.shop?.imageBase64 ? (
                <div className="text-14px text-secondary font-hiragino ">
                  <img
                    src={getShopByID?.shop?.imageBase64}
                    alt={getShopByID?.shop?.name}
                    className="h-120px mt-10px rounded-8px border-solid border-1px border-lightGray"
                  />
                </div>
              ) : (
                <div className="h-20p text-14px text-secondary font-hiragino ">
                  -
                </div>
              )}
            </div>
            <div className="flex flex-col gap-8px">
              <div className="h-20px text-14px font-semibold text-#111827 font-hiragino">
                営業時間
              </div>
              <div className="text-14px text-secondary font-hiragino leading-5">
                {getShopByID?.shop?.openingHours ? (
                  <div
                    className="break-all"
                    dangerouslySetInnerHTML={{
                      __html: getShopByID?.shop?.openingHours.replace(
                        /\n/g,
                        "<br />"
                      ),
                    }}
                  />
                ) : (
                  "-"
                )}
              </div>
            </div>
            <div className="flex flex-col gap-8px">
              <div className="text-14px font-semibold text-#111827 font-hiragino">
                住所
              </div>
              <div className="text-14px text-secondary font-hiragino leading-5 break-all">
                {getShopByID?.shop?.address || "-"}
              </div>
            </div>
            <div className="flex flex-col gap-8px">
              <div className="h-20px text-14px font-semibold text-#111827 font-hiragino">
                お問い合わせ（電話番号など）
              </div>
              <div className="text-14px text-secondary font-hiragino leading-5">
                {getShopByID?.shop?.phoneNumber || "-"}
              </div>
            </div>
            <div className="flex flex-col gap-8px">
              <div className="h-20px text-14px font-semibold text-#111827 font-hiragino break-all">
                ホームページURL
              </div>
              <div className="text-14px text-secondary font-hiragino leading-5">
                {getShopByID?.shop?.homepageUrl || "-"}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[24px] bg-white rounded-[16px]">
          <div className="flex justify-between h-[64px] px-[24px] border-b-[1px] border-b-lightGray border-b-solid text-18px leading-17 font-semibold font-hiragino">
            ショップ用QRコード
          </div>
          <div className="flex flex-col gap-12px  p-[24px]">
            <div className="text-12px text-secondary font-hiragino">
              こちらのQRコードを印刷してレジ前などにおいてください
            </div>
            <div className="flex flex-col gap-20px">
              <div className="w-150px h-150px">
                <Canvas
                  text={getQRURL(
                    (data?.serviceId as string) || "",
                    getShopByID?.shop?.slug || ""
                  )}
                  logo={{ src: psImg, options: { width: 30 } }}
                  options={{
                    errorCorrectionLevel: "H",
                    margin: 0,
                    scale: 4,
                    width: 150,
                  }}
                />
              </div>

              <button
                type="button"
                onClick={handleDownloadPNG}
                className="block w-[152px] h-[36px] py-[8px] px-[12px] rounded-[8px] bg-#F8FAFC font-hiragino border-[1px] border-lightGray border-solid hover:bg-gray-100 hover:cursor-pointer focus:outline-none"
              >
                <div className="text-14px font-semibold text-#111827 font-hiragino leading-4.5">
                  ダウンロード
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        ref={canvasRef}
        className="w-260px p-20px fixed top-[-1000px] left-[-1000px]"
        style={{ display: isDownloadMode ? "block" : "none" }}
      >
        <div className="w-260px h-260px">
          <Canvas
            text={getQRURL(
              (data?.serviceId as string) || "",
              getShopByID?.shop?.slug || ""
            )}
            logo={{
              src: psImg,
              options: { width: 53 },
            }}
            options={{
              errorCorrectionLevel: "H",
              margin: 0,
              scale: 4,
              width: 260,
            }}
          />
        </div>
        <div className="h-12px"></div>
        <div className="text-20px text-#000 text-center">
          {getShopByID?.shop?.name || ""}
        </div>
      </div>
    </>
  );
};

export default ShopSettingPage;
