import { createPromiseClient } from "@connectrpc/connect";
import { createConnectTransport } from "@connectrpc/connect-web";
import { ShopConsoleService } from "schema/gen/es/foodlosscoupon/shopconsole/v1/shopconsole_connect";
import { withTQueryHelpers } from "./queryUtils";
import { Message, PlainMessage } from "@bufbuild/protobuf";
import { STORAGE_KEY } from "../constants/storage";

const transport = createConnectTransport({
  baseUrl: location.origin,
  useBinaryFormat: import.meta.env.PROD,
  useHttpGet: true,
  interceptors: [
    (next) => async (req) => {
      const token = localStorage.getItem(STORAGE_KEY.TOKEN)?.replace(/^"|"$/g, "");
      req.header.set("Authorization", `Bearer ${token}`);
      return await next(req);
    },
  ],
});

/**
 * persistQueryClientでIDBに保存するときにメソッドなどは消えてしまうので、
 * 予めここでメソッドの部分は型から除いておく
 */
const castResponseAsPlain = <T>(client: T) =>
  client as CastResponseAsPlainObject<T>;
type CastResponseAsPlainObject<T> = {
  [K in keyof T]: CastResponseAsPlain<T[K]>;
};
type CastResponseAsPlain<T> = T extends (
  ...args: infer Args
) => Promise<infer Return extends Message<Return>>
  ? ((...args: Args) => Promise<PlainMessage<Return>>) & {
      [K in keyof T]: T[K];
    }
  : T;

export const api = castResponseAsPlain(
  withTQueryHelpers(
    ShopConsoleService.typeName,
    createPromiseClient(ShopConsoleService, transport)
  )
);
